@import '../../../styles/vars';

.convertingPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 530px;
  padding: 92px 0 88px;
  h2 {
    max-width: 282px;
    margin: 0 auto;
    margin-top: 20px;
    color: $text-black;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  }
  .animation {
    width: 132px;
    height: 132px;
  }
}

@media screen and (max-width: 767px) {
  .convertingPopup {
    width: 100%;
    min-width: calc(100% - 32px);

    padding: 40px 20px;
    h2 {
      max-width: 305px;
      margin: 0;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
    }
  }
}
