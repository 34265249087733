@import '../../../styles/vars';

.restorePasswordSentPopup {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 530px;
  padding: 36px 64px;
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 125px;
    background-color: $bg-light-blue;
    border-radius: 10px;
    content: '';
  }
  img {
    z-index: 1;
    margin-bottom: 16px;
  }
  h2 {
    margin-top: 0;
    margin-bottom: 12px;
    color: $text-black;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
  }
  p {
    margin: 0;
    color: #242424;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  b {
    color: #242424;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }
}

@media screen and (max-width: 767px) {
  .restorePasswordSentPopup {
    width: 100%;
    min-width: 100%;
    padding: 28px 16px 24px;
    h2 {
      margin-bottom: 24px;
      font-weight: 500;
      font-size: 22px;
      line-height: 30px;
    }
  }
}
