.popupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10005;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
}
.innerWrapper {
  position: relative;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 16px;
}
.closeIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  &:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 767px) {
  .popupWrapper {
    top: -55px;
    width: 100%;
    height: 108vh;
  }
  .innerWrapper {
    position: relative;
    max-height: 90vh;
    width: 100%;
    max-width: calc(100% - 32px);
  }
  .closeIcon {
    // filter: brightness(2);
    top: 16px;
    right: 16px;
    width: 16px;
  }
}
