$text-black: #1D1D1D;
$text-gray: #878787;
$text-white: #FFFFFF;
$text-blue: #5F30E2;
$text-red: #FF6A48;

$bg-red: #FF6A48;
$bg-gray: #F7F7F7;
$bg-orange: #FF8000;
$bg-green: #009B63;
$bg-violet: #B15ED2;
$bg-blue: #7B66F2;
$bg-light-blue: #EBE7F5;
$bg-white: #FFFFFF;
$bg-pink: #C760AA;
$bg-purple: #C289D7;
$bg-dark-blue: #5F30E2;

$btn-stroke: #BEAFEA;
$btn-white: #FFFFFF;
$btn-blue: #5F30E2;
$btn-blue-hover: #5027C1;
$btn-black: #404040;
$btn-gray: #DEDEDE;
$btn-light-gray: #F9F9F9;
$btn-light-blue: #EBE7F5;
$btn-red: #FF6A48;

$icons-gray-1: #D9D9D9;
$icons-blue: #5F30E2;
