@import "styles/vars";

.deleteDocumentPopup {
  padding: 40px 40px 32px;
  max-width: 530px;
  text-align: center;
  h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 36px;
    color: $text-black;
  }
}
.buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px;
}
button.button {
  padding: 16px 61px;
}

.buttonSubmit {
  padding: 16px 61px;
  background-color: #D2294B;
  &:hover {
    background-color: #dc2626;
  }

@media screen and (max-width: 767px) {
  .deleteDocumentPopup{
    padding: 28px 24px;
    h2 {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 24px;
    }
  }
  .buttons {
    grid-column-gap: 12px;
  }
  button.button {
    padding: 16px 58px;
  }
}
}