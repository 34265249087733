@import "styles/vars";

.dropdownTrigger {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $text-black;
  &:hover {
    cursor: pointer;
    color: $btn-blue-hover;
    svg {
      fill:  $btn-blue-hover;
    }
    span {
      color: $btn-blue-hover;
    }
  }
  &.active {
    color: $text-blue;
    svg {
      fill:  $text-blue;
    }
    span {
      color: $btn-blue-hover;
    }
  }
  span {
    font-weight: 600;
    display: block;
    margin-left: 6px;
  }
}
.dropdownTriggerRtl {
  span {
    margin-left: 0;
    margin-right: 6px;
  }
}
