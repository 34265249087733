@import 'styles/vars';

.converter {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  padding: 16px;
  background-color: #F6F6F6;
  border: 2px solid transparent;
  border-radius: 6px;
  transition: all 0.1s;
  span {
    color: #1D1D1D;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
}
.icons {
  position: relative;
  display: block;
  width: 32px;
  height: 32px;
}

@media screen and (max-width: 767px) {
  .converter {
    min-width: 130px;
    max-width: 300px;

    span {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .icons {
    position: relative;
    display: block;
  }
}
