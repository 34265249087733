@import "../../../styles/vars";

.convertingPopup {
  min-width: 530px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 0;
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    margin-top: 20px;
    color: #1D1D1D;
  }
  .animation {
    width: 350px;
    height: 350px;
  }
}


@media screen and (max-width: 767px) {
  .convertingPopup {
    min-width: calc(100% - 32px);
    width: 100%;
    h2 {
      font-weight: 500;
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 24px;
    }
  }
}
