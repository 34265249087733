.progress {
  --progress: 0%;
  position: relative;
  z-index: 1;
  flex-shrink: 0;
  width: 100%;
  height: 6px;
  overflow: hidden;
  background-color: #dedede;
  border-radius: 100px;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background-color: #14857a;
    transform: translateX(calc(var(--progress) - 100%));
    transition: all 1.3s cubic-bezier(0.5, 0.35, 0.15, 1);
    content: '';
  }
}