@import "styles/vars";

.dragNDrop {
  // border: 1px dashed #F33157;
  padding: 20px;
  width: 683px;
  border-radius: 44px;
  overflow: hidden;
  input {
    display: none;
  }
  &.red {
    border: 1px dashed #F33157;
  }
  &.orange {
    border: 1px dashed #DE6627;
  }
  &.violet {
    border: 1px dashed #A110B9;
  }
  &.berry {
    border: 1px dashed #D2294B;
  }
  &.darkblue {
    border: 1px dashed #3E4FE0;
  }
  &.green {
    border: 1px dashed #008554;
  }
  &.peach {
    border: 1px dashed #E25F49;
  }
  &.oceanblue {
    border: 1px dashed #139AC1;
  }
}
.dropZone {
  background-color: #D2294B;
  min-width: 100%;
  border-radius: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  &.orange {
    background-color: #DE6627;
  }
  &.violet {
    background-color: #A110B9;
  }
  &.berry {
    background-color: #D2294B;
  }
  &.darkblue {
    background-color: #3E4FE0;
  }
  &.green {
    background-color: #008554;
  }
  &.peach {
    background-color: #E25F49;
  }
  &.oceanblue {
    background-color: #139AC1;
  }
}
.content {
  padding-top: 90px;
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  span {
    margin-top: 24px;
    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 50px;
  }
}
.uploadButton {
  background-color: #FFF;
  border-radius: 100px;
  width: 89px;
  box-shadow: 2px 2px 0px 0px #1D1D1D;
  padding: 28px 0;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 28px;
  display: flex;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
}


@media screen and (max-width: 1024px) {
  .dragNDrop {
    max-width: 700px;
  }
}

@media screen and (max-width: 600px) {
  .dragNDrop {
    border-radius: 0px;
    border: none;
    width: 100%;
    max-width: 400px;
    padding: 0;
    &.orange {
      border: none;
    }
    &.violet {
      border: none;
    }
    &.berry {
      border: none;
    }
    &.darkblue {
      border: none;
    }
    &.green {
      border: none;
    }
    &.peach {
      border: none;
    }
    &.oceanblue {
      border: none;
    }
  }
  .dropZone {
    border-radius: 12px;
  }

  .content {
    display: none;
    padding-top: 81px;
    span {
      margin-top: 0;
      margin: 24px auto;
    }
  }
  .delimeter {
    display: none;
  }
  .uploadButton {
    width: 64px;
    height: 64px;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 24px;
      height: fit-content;
      max-width: fit-content;
    }
  }
}
