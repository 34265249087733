@import "styles/vars";

.convertDropdown {
  display: flex;
  justify-content: space-around;
  background: #FFFFFF;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0 auto;
  margin-top: 6px;
  padding-top: 40px;
  padding-bottom: 40px;
  .list {
    display: flex;
    flex-direction: column;
    span {
      width: 100%;
      max-width: 155px;
      height: 43px;
      font-size: 16px;
      color: #1D1D1D;
      font-weight: 800;
      display: block;
      margin-bottom: 20px;
    }
    .item {
      margin-bottom: 16px;
      font-size: 16px;
      display: flex;
      img {
        margin-right: 8px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.editPdf {
  a {
    display: flex;
    align-items: center;
  }
  &:hover, &:active {
    span {
      color: $btn-blue-hover
    }
    img {
      &:nth-child(1) {
        display: none;
      }
      &:nth-child(2) {
        display: block;
      }
    }
  }
  img {
    margin-right: 6px;
    &:nth-child(2) {
      display: none;
    }
  }
  span {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #1D1D1D;
  }
}
.dropDownTrigger {
  margin-right: 34px;
  color: #1D1D1D;
  span {
    font-weight: 700;
    color: #1D1D1D;
    &:hover {
      color: $btn-blue-hover;
    }
  }
}
.dropDownTriggerRtl {
  margin-right: 0;
  margin-left: 34px;
}
