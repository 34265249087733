@import 'styles/vars';

.uploadSection {
  position: relative;
  color:#1D1D1D;
  border-radius: 44px;
  background: #F6F6F6;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 58px;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 318px;
    content: '';
  }
  p {
    max-width: 672px;
    margin: 0 0 48px;
    color: #1D1D1D;
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    text-align: center;
  }
  input {
    display: none;
  }
}
.dragNDrop {
  display: block;
}
.uploadButton {
  display: none;
  width: calc(100vw - 34px);
  padding: 17px 0;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  &.orange {
    background-color: #FF833F;
  }
  &.violet {
    background-color: #C171ED;
  }
  &.berry {
    background-color: #D2294B;
  }
  &.darkblue {
    background-color: #3E4FE0;
  }
}

.error {
  display: none;
  &.visible {
    position: fixed;
    z-index: 10;
    align-items: center;
    width: 100%;
    max-width: 343px;
    border-radius: 8px;
    top: 92px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    padding: 16px 14px 16px 20px;
    color: #FFFFFF;
    background: #FF6A48;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }
}

@media screen and (max-width: 767px) {
  .uploadSection {
    border-radius: 0px;
  }
  .uploadButton {
    display: none;
  }
  .dragNDrop {
    border-radius: 12px;
    width: 100%;
  }
  .error.visible {
    top: 65px;
  }
  .uploadSection {
    padding: 44px 16px 48px;
    background-color: #F7F7F7;
    &:before {
      height: calc(100%);
    }
    p {
      padding: 0 28px;
      margin: 0 0 24px;
      font-size: 18px;
      font-weight: normal;
      text-align: center;
    }
  }
}
