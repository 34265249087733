@import "vars";

.input {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  position: relative;
  &.no-valid {
    input {
      border-color: $bg-red;
      color: $text-red;
    }
  }
  &.valid {
    input {
      border-color: $bg-green;
    }
  }
  .error {
    margin-left: 4px;
    margin-top: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $text-red;
  }
  label {
    margin-bottom: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
  input {
    width: 100%;
    height: 54px;
    border-radius: 5px;
    border: 1px solid $btn-gray;
    padding: 15px 16px;
    color: $text-black;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    outline: none;
    background-color: $bg-gray;
    &::placeholder {
      color: $text-gray;
    }
  }
}
