@import 'styles/vars';


.logoWrapper {
  margin-right: 110px;
  display: flex;
  align-items: center;
  a {
    display: block;
  }
  &:hover,
  a:hover {
    cursor: pointer;
  }
}

.logoWrapperRtl {
  margin-right: 0;
  margin-left: 34px;
}

.desktopNav {
  display: flex;
  gap: 16px;
  align-items: center;
}
button.dashboardButton {
  border-radius: 12px;
  border: 1px solid #1D1D1D;
  background: #F6F6F6;
  min-width: 140px;
  padding: 12px 20px;
  color: #1D1D1D;
  &:hover {
    background: #1D1D1D;
    color: #FFFFFF;
  }
  // > a {
  //   &:hover {
  //   background: #1D1D1D;
  //   }
  // }
}
button.loginButton {
  border-radius: 12px;
  border: 1px solid #1D1D1D;
  padding: 0;
  > a {
    color: #1D1D1D;
    display: block;
    width: 140px;
    padding: 12px 0;
    // background: #F6F6F6;
     &:hover {
      background: #1D1D1D;
      color: #FFFFFF;
    }
  }
}
button.logoutButton {
  border: 1px solid #1D1D1D;
  background: #F6F6F6;
  color: #1D1D1D;
  border-radius: 12px;
  min-width: 140px;
  padding: 12px 20px;
    &:hover {
      background: #1D1D1D;
      color: #FFFFFF;
  }
}

@media screen and (max-width: 899px) {
  .header {
    padding: 20px 48px;
  }
}

@media screen and (max-width: 767px) {
  .header {
    display: none;
  }
  .desktopNav {
    display: none;
  }
  .header {
    background-color: #F7F7F7;
    position: fixed;
    padding: 20px 0;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    .logoNav {
      justify-content: space-between;
      width: 100%;
    }
    .logoWrapper {
      margin-right: 0;
      img {
        max-width: 132px;
      }
    }
  }
}
