@import 'styles/vars';

.enterEmailPopup {
  width: 100%;
  width: 562px;
  padding: 32px 48px;
  > h2 {
    margin-bottom: 32px;
    color: $text-black;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  }
}
button.submitButton {
  width: 100%;
  margin-top: 16px;
}

.oneTapWrapper > * {
  z-index: 11000 !important;
}

@media screen and (max-width: 767px) {
  .enterEmailPopup {
    width: 100%;
    min-width: calc(100vw - 32px);
    padding: 28px 16px;
    > h2 {
      margin-top: 12px;
      margin-bottom: 24px;
      font-size: 20px;
      line-height: 30px;
    }
  }
  button.submitButton {
    margin-top: 16px;
  }
}
