// @use 'input' as *;

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./global.scss";
@import "./_input.scss";

@layer base {
  @font-face {
    font-family: "Gilroy Refular";
    font-weight: 400;
    font-style: normal;
    src: url('../fonts/Gilroy-Regular.woff2') format('woff2')
  }

  @font-face {
    font-family: "Gilroy Medium";
    font-weight: 500;
    font-style: normal;
    src: url('../fonts/Gilroy-Medium.woff2') format('woff2')
  }

  @font-face {
    font-family: "Gilroy Semibold";
    font-weight: 600;
    font-style: normal;
    src: url('../fonts/Gilroy-Semibold.woff2') format('woff2')
  }

  @font-face {
    font-family: "Gilroy Bold";
    font-weight: 700;
    font-style: normal;
    src: url('../fonts/Gilroy-Bold.woff2') format('woff2')
  }

  @font-face {
    font-family: "Gilroy Extrabold";
    font-weight: 800;
    font-style: normal;
    src: url('../fonts/Gilroy-Extrabold.woff2') format('woff2')
  }

}
