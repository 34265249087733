@import "styles/vars";

.mobileNav {
  position: fixed;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  top: 54px;
  left: 0;
  width: 100vw;
  background-color: #F7F7F7;
  padding: 8px 16px 170px;
  box-shadow: 0px 10px 4px 0px rgba(91, 91, 91, 0.16);
}
.lists {
  display: grid;
  grid-template-columns: 45% 45%;
  row-gap: 12px;
  justify-content: space-between;
  margin-bottom: 12px;
}
.list {
  display: flex;
  flex-direction: column;
  span {
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #1D1D1D;
    margin-bottom: 8px;
  }
  .item {
    text-align: left;
  }
}
.buttons {
  display: flex;
  flex-direction: column;
  gap: 12px
}
.loginButton {
  width: 100%;
  background-color: #F7F7F7;
  color:#1D1D1D;
  border-radius: 12px;
  padding: 14px 0;
  > a {
    &:hover {
      background: #1D1D1D;
      color: #FFFFFF;
    }
  }
}
.dashboardButton {
  background: #F7F7F7;
  color: #1D1D1D;
  > a {
    &:hover {
      background: #1D1D1D;
      color: #FFFFFF;
    }
  }
}
