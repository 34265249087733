@import 'styles/vars';

.converter {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  max-width: 267px;
  padding: 16px;
  background-color: #F6F6F6;
  border: 2px solid #F6F6F6;
  border-radius: 6px;
  transition: all 0.1s;
  span {
    color: #1D1D1D;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
}
.icons {
  position: relative;
  display: block;
  width: 32px;
  height: 32px;
}

@media screen and (max-width: 600px) {
  .converter {
    min-width: 130px;
    max-width: 220px;

    span {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .icons {
    position: relative;
    display: block;
  }
}

@media screen and (max-width: 430px) {
  .converter {
    min-width: 130px;
    max-width: 172px;
  }
}

@media screen and (max-width: 423px) {
  .converter {
    min-width: 130px;
    max-width: 167px;
  }
}

@media screen and (max-width: 413px) {
  .converter {
    min-width: 130px;
    max-width: 162px;
  }
}

@media screen and (max-width: 403px) {
  .converter {
    min-width: 130px;
    max-width: 159px;
  }
}

@media screen and (max-width: 397px) {
  .converter {
    min-width: 130px;
    max-width: 155px;
  }

}

@media screen and (max-width: 389px) {
  .converter {
    min-width: 130px;
    max-width: 150px;
  }
}

@media screen and (max-width: 379px) {
  .converter {
    min-width: 130px;
    max-width: 147px;
  }
}



