@import "styles/vars";

.restorePasswordPopup {
  min-width: 530px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 39px 64px;
  h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    margin-top: 0;
    margin-bottom: 36px;
    color: $text-black;
  }
}
.form {
  width: 100%;
}
.inputWrapper {
  margin-bottom: 24px;
}
.actionButton {
  width: 100%
}
@media screen and (max-width: 767px) {
  .restorePasswordPopup {
    min-width: calc(100% - 32px);
    width: 100%;
    padding: 28px 24px;
    h2 {
      font-weight: 500;
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 24px;
    }
  }
  .inputWrapper {
    margin-bottom: 16px;
  }
}