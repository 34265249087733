@import "styles/vars";

.iconButton {
  display: flex;
  align-items: center;
  color: #1D1D1D;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  transition: all .3s;
  margin-bottom: 10px;
  &:hover, *>:hover {
    color: $btn-blue-hover;
  }
  img {
    width: 40px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 390px) {
  .iconButton {
    img {
      width: 28px;
    }
  }
}

@media screen and (max-width: 375px) {
  .iconButton {
    img {
      width: 24px;
    }
  }
}

