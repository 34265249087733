@import "styles/vars";

.secondaryButton {
  padding: 11px 16px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid $btn-stroke;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  color: $text-black;
  transition: all .3s;
  overflow: hidden;
  &:hover, a:hover {
    cursor: pointer;
    background-color: $btn-light-blue;
    transition: all .3s;
    color: $text-black;
  }
  &:active {
    background-color: $btn-blue;
    border-color: $btn-blue;
    color: $text-white;
  }
  &:disabled {
    background-color: $btn-gray;
    color: $text-gray;
    &:hover {
      cursor: not-allowed;
    }
  }
}
